.pagination
{
    margin: 20px 0;
}
.pagination ul
{
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination ul > li
{
    display: inline;
}
.pagination ul > li > a,
.pagination ul > li > span
{
    float: left;
    padding: 4px 12px;
    line-height: 20px;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-left-width: 0;
}
.pagination ul > li > a:hover,
.pagination ul > li > a:focus,
.pagination ul > .active > a,
.pagination ul > .active > span
{
    background-color: #f5f5f5;
    cursor: pointer;
}
.pagination ul > .active > a,
.pagination ul > .active > span
{
    color: #999999;
    cursor: default;
}
.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover,
.pagination ul > .disabled > a:focus
{
    color: #999999;
    background-color: transparent;
    cursor: default;
}
.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span
{
    border-left-width: 1px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
}
.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span
{
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
}
.pagination-centered
{
    text-align: center;
}
.pagination-right
{
    text-align: right;
}
.pagination-large ul > li > a,
.pagination-large ul > li > span
{
    padding: 11px 19px;
    font-size: 17.5px;
}
.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span
{
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px;
}
.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span
{
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px;
}
.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span
{
    -webkit-border-top-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-left-radius: 3px;
}
.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span
{
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    border-bottom-right-radius: 3px;
}
.pagination-small ul > li > a,
.pagination-small ul > li > span
{
    padding: 2px 10px;
    font-size: 11.9px;
}
.pagination-mini ul > li > a,
.pagination-mini ul > li > span
{
    padding: 0 6px;
    font-size: 10.5px;
}
.pager
{
    margin: 20px 0;
    list-style: none;
    text-align: center;
    *zoom: 1;
}
.pager:before,
.pager:after
{
    display: table;
    content: "";
    line-height: 0;
}
.pager:after
{
    clear: both;
}
.pager li
{
    display: inline;
}
.pager li > a,
.pager li > span
{
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus
{
    text-decoration: none;
    background-color: #f5f5f5;
}
.pager .next > a,
.pager .next > span
{
    float: right;
}
.pager .previous > a,
.pager .previous > span
{
    float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span
{
    color: #999999;
    background-color: #fff;
    cursor: default;
}