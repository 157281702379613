/*
*/
/* 
    Created on : 10.05.2014, 01:11:11
    Author     : Simon Schoen
*/

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
}

#wrap {
    min-height: 100%;
}

#main {
    overflow: auto;
    padding-bottom: 150px; /* this needs to be bigger than footer height*/
}

.footer {
    position: relative;
    margin-top: -150px; /* negative value of footer height */
    height: 150px;
    clear: both;
    padding-top: 20px;
}

.errorMsg {
    font-weight: bold;
    color: #FF0000;
}

.successMsg {
    font-weight: bold;
    color: #009a0e;
}

.bias-input-group .input-group-addon + .input-group-btn > .btn
{
    border-radius: 0;
}

.bias-input-group .input-group-addon + .input-group-btn + .input-group-addon
{
    border-right: 0;
    border-left: 0;
}